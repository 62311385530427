import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase";

import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { MyFooter } from "./Footer";
import "./css/lobby.css";
import Swal from "sweetalert2";
import { useOnlineState } from "./hooks/useOnlineState";
import tablero from "./assets/tablero.png";
import logo from "./images/logo-megachess.png";

import { MyNavbar } from "./Navbar";

export function Landing() {
  //idioma
  var idioma_seleccionado = localStorage.getItem("idioma");
  if (idioma_seleccionado == null) {
    idioma_seleccionado = "Español";
    localStorage.setItem("idioma", "Español");
  }
  const [idioma, setIdioma] = useState(idioma_seleccionado);
  //fin idioma
  
  //para guardar llave de juego
  const [llave, setLlave] = useState('vacia');
  const [llaveseleccionada, setLlaveseleccionada] = useState('no');
  
  const isOnline = useOnlineState();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  
  
  const createGame45 = () => {
    window.location = "/game";
    localStorage.setItem("clave_privada", "");
    localStorage.setItem("time", "true");
    localStorage.setItem("minutes", 45);
    localStorage.setItem("moves", "true");
    return;
  };
  
  const [lobby, setLobby] = useState({});
  useEffect(() => {
    const lobbyRef = firebase.database().ref("lobby");
    
    lobbyRef.on("value", (snap) => {
      if (snap?.val() === null) {
        return;
      }
      setLobby(snap.val());
    });
    
    return () => {
      lobbyRef.off("value");
    };
  }, [dispatch]);
  
  
  //buscar id de la partida jugable
  Object.keys(lobby).map((key) => {
    const element = lobby[key];
    const ahorita = new Date();
    const hoy = ahorita.getFullYear() + "-" + ("0" + (ahorita.getMonth() + 1)).slice(-2) + "-" + ("0" + ahorita.getDate()).slice(-2);
    const fechacreacion = element.fecha_creacion;
    
    if (element.creador === "Anonimo" &&
    element.status === "waiting" &&
    hoy == fechacreacion &&
    llaveseleccionada == 'no'
    ) 
    {
      //guarda key
      setLlave(key);
      setLlaveseleccionada('si');
    }
  });
  
  
  
  function handleSubmit(event) {
    event.preventDefault();
    const clave = event.currentTarget.elements.clave_sala.value;
    const sala = event.currentTarget.elements.id_sala.value;
    
    //buscamos si coincide
    const referencia = firebase
    .database()
    .ref("lobby/" + sala + "/clave_privada");
    referencia.on("value", (snap) => {
      if (snap?.val() === null) {
        return;
      }
      const clave_guardada_sala = snap.val();
      if (clave_guardada_sala === clave) {
        window.location = `/game/${sala}`;
      } else {
        Swal.fire({
          title: "Opps..",
          text: "Clave incorrecta",
        });
      }
    });
  }
  
  return (
    <section className="fondo_principal">
    <MyNavbar />
    
    <Container style={{ paddingTop: "40px" }}>
    <Row>
    <Col xs={12} lg={6} className="centrar">
    <a href='#!' onClick={createGame45}>
    <img src={tablero} alt="" className="img-fluid"></img>
    </a>  
    </Col>
    <Col xs={12} lg={6} className="d-flex flex-column justify-content-center align-items-center" >     
    <img src={logo} alt="" style={{ height: "70px", marginTop:"30px", marginBottom:"60px"}}></img>
    
    {idioma == "English" ? (
      <div className="d-flex flex-column justify-content-center align-items-center">
      <h4 style={{color:"white"}}>With 27 pieces and 90 movements</h4>
      <h4 style={{color:"white"}}>The triumph is yours.</h4>
      <Button  onClick={createGame45} className="btn-lg" style={{ marginTop:"25px", padding:"2rem 6rem", marginBottom:"10px"}}>Create a game</Button>

      {llave != 'vacia' ? (
          <Button onClick={() =>(window.location = `/game/${llave}`)} className="btn-lg" style={{ marginTop:"25px", padding:"2rem 6.7rem", marginBottom:"10px"}}>Join a game</Button>
          ) : (
            <div></div>
            )}     
      
      
      <p className="mt-auto" style={{fontSize:"10px"}}>Leochess90 © 2022 All rights reserved</p>
      </div>  
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
        <h4 style={{color:"white"}}>Con 27 piezas en 90 movimientos</h4>
        <h4 style={{color:"white"}}>El triunfo es tuyo.</h4>
        <Button  onClick={createGame45} className="btn-lg" style={{ marginTop:"25px", padding:"2rem 6rem", marginBottom:"10px"}}>Crear una partida</Button>
        
        {llave != 'vacia' ? (
          <Button onClick={() =>(window.location = `/game/${llave}`)} className="btn-lg" style={{ marginTop:"25px", padding:"2rem 5rem", marginBottom:"10px"}}>Unirme a una partida</Button>
          ) : (
            <div></div>
            )}
            
            <p className="mt-auto" style={{fontSize:"10px"}}>Leochess90 © 2022 Todos los derechos reservados</p>
            </div>    
            )}    
            
            
            
            </Col>
            </Row>
            <Row id="no-more-tables" style={{marginTop:"30px"}}>
            {idioma == "English" ? (
              <h2 style={{color:"white",margin:"0 auto" }}>Join a game</h2>
              ) : (
                
                <h2 style={{color:"white",margin:"0 auto"}}>Unirse a una partida</h2>
                )}  
                
                <table className="table">
                <thead>
                {idioma == "English" ? (
                  <tr>
                  <th style={{ color: "white" }}>Id:</th>
                  <th style={{ color: "white" }}>Host:</th>
                  <th style={{ color: "white" }}>Status:</th>
                  <th style={{ color: "white" }}>Type:</th>
                  <th style={{ color: "white" }}>Options:</th>
                  </tr>
                  ) : (
                    <tr>
                    <th style={{ color: "white" }}>Id:</th>
                    <th style={{ color: "white" }}>Creador:</th>
                    <th style={{ color: "white" }}>Estado:</th>
                    <th style={{ color: "white" }}>Tipo:</th>
                    <th style={{ color: "white" }}>Acciones:</th>
                    </tr>
                    )}
                    </thead>
                    <tbody>
                    {Object.keys(lobby).length > 0 ? (
                      Object.keys(lobby).map((key) => {
                        const element = lobby[key];
                        const ahorita = new Date();
                        const hoy =
                        ahorita.getFullYear() +
                        "-" +
                        ("0" + (ahorita.getMonth() + 1)).slice(-2) +
                        "-" +
                        ("0" + ahorita.getDate()).slice(-2);
                        const fechacreacion = element.fecha_creacion;
                        if (isOnline) {
                          if (element.creador !== "Anonimo") {
                            return (
                              <tr key={key}>
                              <td style={{ color: "white" }} data-title="Id:">
                              {element.id_partida}
                              </td>
                              <td style={{ color: "white" }} data-title="Creador:">
                              <span id="code">Anónimo</span>
                              </td>
                              <td style={{ color: "white" }} data-title="Estado:">
                              {element.status}
                              </td>
                              <td style={{ color: "white" }} data-title="Tipo:">
                              {element.clave_privada === "" ? (
                                <span>Pública</span>
                                ) : (
                                  <span>Privada</span>
                                  )}
                                  </td>
                                  <td style={{ color: "white" }} data-title="Acciones:">
                                  {element?.clave_privada === "" ? (
                                    element?.status !== "playing" &&
                                    element?.status !== "waiting" &&
                                    element?.status !== "pause" ? (
                                      <button
                                      className="btn btn-success"
                                      onClick={() =>
                                        (window.location = `/review/${key}`)
                                      }
                                      >
                                      Analizar
                                      </button>
                                      ) : (
                                        idioma == "English" ? (
                                          <button className="btn btn-primary" onClick={() =>(window.location = `/game/${key}`)}>Play</button>
                                          ) : (
                                            <button className="btn btn-primary" onClick={() =>(window.location = `/game/${key}`)}>Jugar</button>
                                            )
                                            )
                                            ) : element?.status !== "playing" &&
                                            element?.status !== "waiting" &&
                                            element?.status !== "pause" ? (
                                              <button
                                              className="btn btn-success"
                                              onClick={() =>
                                                (window.location = `/review/${key}`)
                                              }
                                              >
                                              Analizar
                                              </button>
                                              ) : (
                                                <form onSubmit={handleSubmit}>
                                                <input
                                                id="clave_sala"
                                                type="text"
                                                placeholder="Ingresa la Clave"
                                                />
                                                <input id="id_sala" type="hidden" value={key} />
                                                <input
                                                style={{ marginLeft: "5px" }}
                                                className="btn btn-info"
                                                type="submit"
                                                value="Entrar"
                                                />
                                                </form>
                                                )}
                                                </td>
                                                </tr>
                                                );
                                              }
                                            } else {
                                              if (
                                                (element.creador === "Anonimo" &&
                                                element.status === "playing" &&
                                                hoy == fechacreacion) ||
                                                (element.creador === "Anonimo" &&
                                                element.status === "waiting" &&
                                                hoy == fechacreacion)
                                                ) {
                                                  return (
                                                    <tr key={key}>
                                                    <td style={{ color: "white" }} data-title="Id:">
                                                    {element.id_partida}
                                                    </td>
                                                    <td style={{ color: "white" }} data-title="Creador:">
                                                    <span id="code">Anónimo</span>
                                                    </td>
                                                    <td style={{ color: "white" }} data-title="Estado:">
                                                    {element.status}
                                                    </td>
                                                    <td style={{ color: "white" }} data-title="Tipo:">
                                                    {element.clave_privada === "" ? (
                                                      <span>Pública</span>
                                                      ) : (
                                                        <span>Privada</span>
                                                        )}
                                                        </td>
                                                        <td style={{ color: "white" }} data-title="Acciones:">
                                                        {element?.clave_privada === "" ? (
                                                          element?.status !== "playing" &&
                                                          element?.status !== "waiting" &&
                                                          element?.status !== "pause" ? (
                                                            <button
                                                            className="btn btn-success"
                                                            onClick={() =>
                                                              (window.location = `/review/${key}`)
                                                            }
                                                            >
                                                            Analizar
                                                            </button>
                                                            ) : (
                                                              idioma == "English" ? (
                                                                <button className="btn btn-primary" onClick={() =>(window.location = `/game/${key}`)}>Play</button>
                                                                ) : (
                                                                  <button className="btn btn-primary" onClick={() =>(window.location = `/game/${key}`)}>Jugar</button>
                                                                  )
                                                                  )
                                                                  ) : element?.status !== "playing" &&
                                                                  element?.status !== "waiting" &&
                                                                  element?.status !== "pause" ? (
                                                                    <button
                                                                    className="btn btn-success"
                                                                    onClick={() =>
                                                                      (window.location = `/review/${key}`)
                                                                    }
                                                                    >
                                                                    Analizar
                                                                    </button>
                                                                    ) : (
                                                                      <form onSubmit={handleSubmit}>
                                                                      <input
                                                                      id="clave_sala"
                                                                      type="text"
                                                                      placeholder="Ingresa la Clave"
                                                                      />
                                                                      <input id="id_sala" type="hidden" value={key} />
                                                                      <input
                                                                      style={{ marginLeft: "5px" }}
                                                                      className="btn btn-info"
                                                                      type="submit"
                                                                      value="Entrar"
                                                                      />
                                                                      </form>
                                                                      )}
                                                                      </td>
                                                                      </tr>
                                                                      );
                                                                    }
                                                                  }
                                                                })
                                                                ) : (
                                                                  <tr>
                                                                  <td
                                                                  style={{ color: "white" }}
                                                                  data-title="Mensaje:"
                                                                  colSpan="4"
                                                                  >
                                                                  Aun no hay juegos en espera, ¿por qué no creas uno?
                                                                  </td>
                                                                  </tr>
                                                                  )}
                                                                  </tbody>
                                                                  </table>
                                                                  </Row>
                                                                  </Container>
                                                                  <MyFooter />
                                                                  </section>
                                                                  );
                                                                }
                                                                