export const firebaseConfig = {
  apiKey: "AIzaSyCxSugt-bGMvIR4KFVJq1skc98s8_1NVa4",
  authDomain: "chessjs-2dcfc.firebaseapp.com",
  databaseURL: "https://chessjs-2dcfc-default-rtdb.firebaseio.com",
  projectId: "chessjs-2dcfc",
  storageBucket: "chessjs-2dcfc.appspot.com",
  messagingSenderId: "956455284581",
  appId: "1:956455284581:web:79b1d1b1610119c664fb8c",
  measurementId: "G-YMF21NL9Z2",
};
