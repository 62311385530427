export const GameConst = {
  white: 0,
  black: 1,
  empty: -1,
  pawn: 0,
  knight: 1,
  bishop: 2,
  rook: 3,
  queen: 4,
  king: 5,
  squirrel: 6,
  bunny: 7,
  dog: 8,
  panter: 9,
  elephant: 10,
  lyon: 11,
  fakeking:12,
  invalid: 0,
  valid: 1,
  validCapture: 2,
  tileSize: 58,
  boardWidth: 9,
  boardHeight: 10,
  colors: {
    whiteTileColor: "#B8E37B",
    blackTileColor: "#4A8129",
    middleTileColor: "#90C485",
    highLightTileColor: "#103B08",
  },
};
