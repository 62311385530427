import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col } from "react-bootstrap";

import "./fonts/fontawesome-all.min.css";
import "./fonts/ionicons.min.css";
import "./fonts/line-awesome.min.css";
import "./fonts/fontawesome5-overrides.min.css";
import "./css/Social-Icons.css";

import "./css/menu.css";
import "./css/encabezado.css";
import "./Reglas.css";

import tablero from "./assets/tablero.png";
import serpentina4 from "./images/serpentina-4.png";

import { MyNavbar } from "./Navbar";
import { MyFooter } from "./Footer";

export function Reglas() {
  //idioma
  var idioma_seleccionado = localStorage.getItem("idioma");
  if (idioma_seleccionado == null) {
    idioma_seleccionado = "Español";
    localStorage.setItem("idioma", "Español");
  }
  const [idioma, setIdioma] = useState(idioma_seleccionado);
  //fin idioma

  return (
    <section className="fondo_principal">
      <MyNavbar />

      <section className="encabezado" style={{background:"none"}}>
        <header className="fondo2">
          <Container className="reglas">
            <Row>
              <Col xs={12}>
                {idioma == "English" ? (
                  <div className="site-heading">
                    <h1 className="titulo">Rules</h1>
                    <div align="center" style={{marginBottom:"20px"}}>
                      <img src={serpentina4} style={{}}></img>
                    </div>

                    <h4>RULE NO. 1 INITIAL SETUP</h4>

                    <p>
                      Leochess90 is a new amplified game intimately inspired in
                      traditional chess: a two-player board game using a
                      chessboard in which the player controlling the white
                      pieces is named "White" and the player controlling the
                      black pieces is named "Black". In Leochess90 each player
                      has twenty-seven pieces of twelve types vs the sixteen
                      pieces of 6 different types of the original game.
                    </p>

                    <h4>1.1 THE CHESSBOARD</h4>

                    <p>
                      The Leochess 90 board is a 90 square grid rectangle
                      composed of 10 rows and 9 columns. This board is larger
                      than a traditional 8 by 8 chessboard with 64 squares, but
                      as in its original predecessor the squares alternate
                      between white (or light) squares and black (or dark)
                      squares. Rows are numbered 1 to 10 and columns are
                      assigned a letter in alphabetical order: A, B, C, D, E, F,
                      G, H, I allowing each box to have a particular
                      denomination, for example, A1, B6, E9, H10, etc.
                    </p>

                    <h4>1.2 THE PIECES</h4>

                    <p>
                      Each player starts the game with 27 pieces divided into
                      major (or heavy) pieces, minor pieces, and pawns, besides
                      the King and the Lion, which are special pieces. The main
                      pieces in Leochess can be classified in four main groups:
                    </p>

                    <p>
                      <span>•</span> 3 different kinds of pawns: Pawns, Rabbits and
                      Squirrels.
                    </p>

                    <p>
                      <span>•</span> 3 different kinds of minor pieces: Bishops, Knights and
                      Hounds
                    </p>

                    <p>
                      <span>•</span> 4 different kinds of mayor pieces: Rooks, Panthers,
                      Elephants and Queens
                    </p>

                    <p><span>•</span> 2 special pieces: the King and the Lion</p>

                    <p>
                      To start each player controls 27 pieces divided as
                      follows:
                    </p>
                    
                      <p>1 King</p>
                      <p>1 Lion</p>
                      <p>2 Queens</p>
                      <p>2 Elephants</p>
                      <p>2 Panthers</p>
                      <p>2 Rooks</p>
                      <p>2 Bishops</p>
                      <p>2 Hounds</p>
                      <p>2 Squirrels</p>
                      <p>3 Rabbits</p>
                      <p>6 Pawns.</p>
                    

                    <h4>1.2.1 PIECE VALUE</h4>

                    <p>
                    Each piece has an intrinsic value which drives the players to decide if a certain exchange is convenient, for example, a player wouldn’t choose losing a Rook over a Knight, since the latter has a greater value, unless it meant a strategic advantage on the resulting position. This move is known as “sacrifice”. Even though in the table below the King is given the highest value, 10, it is actually invaluable because its capture means checkmate, it means losing the game.
                    </p>

                    <h4>1.2.2 INITIAL SETUP</h4>

                    <p>
                    At the beginning of the game, the White King is placed in the central white or light square in the first row and the Black King in the central black or dark square in the tenth row. The remaining pieces will be arranged as shown in the following diagram: 
                    </p>
                    <img src={tablero} alt="" className="img-fluid"></img>
                    <br></br>
                    <br></br>
                    <h4>1.3 OBJECT OF THE GAME</h4>

                    <p>
                    The objective of the game is to checkmate –threaten with inescapable capture– the opponent's King or to crown one’s Lions by reaching the opponent’s first row. 
                    </p>

                    <h4>1.4 MOVEMENTS</h4>

                    <p>
                    Each type of chess piece has its own method of movement. A piece moves to a vacant square except when capturing an opponent's piece. Whites move first with a single move, then players alternate moves. When both have concluded their move the first turn is over. Leochess90 has 90 turns, this means each player has 90 moves during a match.
                    </p>

                    <h4>RULE NO. 2 TURNS AND CYCLES</h4>

                    <p>
                    As in traditional chess, in Leochess90 each player only has one move per turn. 
                    </p>

                    <h4>2.1 TURNS</h4>

                    <p>
                    Each turn consists of two movements, white’s and black’s, but when concluding the first 9 turns a cycle is over. Each cycle has 9 turns. 
                    </p>

                    <h4>2.2 CYCLES</h4>

                    <p>
                    The game consists of ten cycles, this means each player has 90 moves. If at the end of the 90 moves, neither has obtained the victory, the game is declared a draw.
                    </p>

                    <h4>2.3 DOUBLE TURN</h4>

                    <p>
                    When the players have completed the first cycle, the Black player has a double turn. Since Black was the last one to move on the ninth turn, the order shifts on the second cycle to give Blacks the chance to get the upper hand; however it is not allowed to move the same piece twice: the first move of the second cycle cannot be the same than the last one of the first cycle. 
                    </p>

                    <p>
                    When the players have completed the second cycle, the White player has a double turn. Since White was the last one to move on the eighteenth turn, the order shifts on the third cycle to give White the chance to get the upper hand again (movements 18 and 19). 
                    </p>

                    <p>
                    This means that White always starts on odd cycles and Black on even cycles. Each player has the upper hand for five cycles during the game.
                    </p>

                    <h4>RULE NO. 3 DURATION</h4>

                    <p>
                    Time is always crucial in chess games. Of course, players can agree on the time they want to devote to the game. For this purpose, the players will have a chess clock. If they wish to do so, players can play indefinitely without a time limit and without a clock, however, in official Leochess90 tournaments there are three possibilities:
                    </p>

                    <p>
                      <span>•</span> The official game standard lasts 90 minutes (45 minutes per player);
                    </p>

                    <p>
                      <span>•</span> The fast game lasts 60 minutes (30 minutes per player); and
                    </p>

                    <p>
                      <span>•</span> The flash game or “Blitz” lasts 30 minutes (15 minutes per player).
                    </p>

                    <p>
                    If any player exceeds their game time, he/she will automatically lose the game. 
                    </p>

                    <h4>RULE NO.4 THE PAWNS</h4>

                    <p>
                    In Leochess90 there are 3 different types of pawns:
                    </p>

                    <p><span>•</span> Pawns</p>

                    <p><span>•</span> Rabbits</p>

                    <p><span>•</span> Squirrels</p>

                    <h4>4.1 THE PAWN</h4>

                    <p>
                    The pawn, as in traditional chess, can move for the first time two sports straight forward (in columns), afterwards it can only move straight forward one square at a time, if the box is vacant. A pawn, unlike other pieces, captures differently from how it moves. A pawn can capture an enemy piece on either of the two squares diagonally in front of it. It cannot move in diagonal except when capturing.
                    </p>

                    <h4>4.2 THE RABBIT</h4>

                    <p>
                    The rabbit is a squared power pawn, this means that, unlike a typical pawn, it can move two squares straight forward in whatever moment of the game. Additionally, besides moving in diagonal to capture, it can move forward in diagonal one square if the box is vacant. 
                    </p>

                    <h4>4.3 THE SQUIRREL</h4>

                    <p>
                    The squirrel is a cubed power pawn, this means that it can move three squares straight forward in whatever moment of the game, as long as no other piece gets in the way. The squirrel, just as the rabbit, captures diagonally, but it can also move one diagonal spot forward without capture. Additionally, the squirrel can move sideways one spot, but it cannot capture in that direction. 
                    </p>

                    <p>
                    All pawns (Pawns, Rabbits and Squirrels) can never move backwards.
                    </p>

                    <p>
                    The pawn family has a hierarchy, the pawn with the lowest rank is the traditional Pawn, followed by the rabbit and finally by the squirrel, which is the highest ranked. 
                    </p>

                    <h4>RULE NO. 5 PAWN CROWNING</h4>

                    <p>
                    If a player advances a pawn to the last row in enemy lines (row 10 for Whites and row 1 for Blacks), the pawn is then promoted (converted) into a piece of higher value. In traditional chess, since there is only one kind of pawn when it gets promoted it can be transformed into any minor or major piece (Queen, Rook, Bishop, or Knight); but given the fact that Leochess90 has 3 different types of pawn, they can only be promoted according to their hierarchy: 
                    </p>

                    <p><span>•</span> The Pawn promotes into Bishop</p>
                    <p><span>•</span> The Rabbit promotes into Rook</p>
                    <p><span>•</span> The Squirrel promotes into Queen</p>

                    <h4>RULE NO. 6 CAPTURING EN PASSENT</h4>

                    <p>
                    Pawns can capture other pawns en passent according to their hierarchies. 
                    </p>

                    <p>
                    A pawn can only capture another pawn en passent, this happens when a pawn advances two squares from its original square and ends the turn adjacent to a pawn of the opponent's on the same rank, it may be captured by that pawn of the opponent's, as if it had moved only one square forward. This capture is only legal on the opponent's next move immediately following the first pawn's advance. In the following example, the White Pawns can only capture en passent if they are on row 7 and can occupy the 8th row after the two square advance by the Black Pawn. In the case of the Blacks, they can only capture en passent other Pawns if they are on the 5th row and can occupy the 4th row after the advance. Capturing en passent is always optional. 
                    </p>

                    <p>
                    Rabbits and Squirrels can also capture Pawns en passent if found in the same conditions. Pawns cannot eat Rabbits or Squirrels en passent due to their higher rank.
                    </p>

                    <p>
                    Rabbits can capture other Rabbits en passent, this happens whenever a Rabbit advances two spots and the enemy Rabbit can occupy the vacant square after the move. This can happen anywhere on the board. Rabbits cannot capture Squirrels en passent, but Squirrels can capture Rabbits en passent if found in the same conditions. 
                    </p>

                    <p>
                    Squirrels can capture other Squirrels en passent. Since Squirrels can move up to three spots at a time, they leave not one but two vacant squares behind after each move. If an enemy Squirrel can occupy any of the vacant spots, it can capture the enemy Squirrel en passent. This can happen anywhere on the board. Squirrels can also capture enemy Rabbits and Pawns en passent. 
                    </p>

                    <h4>RULE NO. 7 CAPTURE BY LEAP</h4>

                    <p>
                    In Leochess90, besides capturing en passent, Squirrels and Rabbits can capture by leap. Capturing by leap means that, if a lower-ranked Pawn is in front of a higher-ranked Pawn, the latter can capture it by jumping forward as long as the square behind the lower-ranked Pawn is vacant. The traditional Pawn cannot capture by leap since there are no lower-ranked pieces; however, Rabbits can leap over enemy Pawns, and Squirrels can leap over enemy Rabbits and Pawns. This ability is exclusive to the pawn family, no other piece can capture by leap. 
                    </p>

                    <h4>7.1   LEAPING OVER YOUR OWN PAWNS</h4>

                    <p>
                        Rabbits can leap over their team’s Pawns and Squirrels can leap over their team’s Rabbits and Pawns without capturing. This allows the pieces to have greater mobility on the chessboard. 
                    </p>


                    <h4>RULE NO. 8 MINOR PIECES</h4>

                    <p>In Leochess90 there are 3 minor pieces:</p>

                    <p><span>•</span> Bishops</p>
                    <p><span>•</span> Knights</p>
                    <p><span>•</span> Hounds</p>

                    <h4>8.1 THE BISHOP</h4>

                    <p>
                    The bishop is the most powerful of the minor pieces since it dominates all of its color’s diagonals on the board. Leochess90’s bishop has the same movements as the traditional Bishop; however, there is a notable distinction: in traditional chess each player has one bishop for the white squares and one for the black squares whilst in Leochess90 bishops are both the same color for each player; this means that the Whites dominate all white diagonals and Blacks dominate all the black ones, modifying the strategic sense of the game. The only way to get a Bishop of the opposing color is by promoting a pawn in a square of the desired color. 
                    </p>

                    <h4>8.2 THE KNIGHT</h4>

                    <p>
                    The knight is the only minor piece that can leap over other pieces. It can move two squares vertically and one square horizontally, or two squares horizontally and one square vertically thus forming an “L” shape. The movement can be visualized as a 6 square rectangle in which the Knight occupies one of the corners and moves to the opposite corner. 
                    </p>

                    <p>
                    The Knights move in the same way as in traditional chess and are ideal to attack two or more pieces simultaneously.
                    </p>

                    <h4>8.3 THE HOUND</h4>

                    <p>
                    The Hound is a minor piece exclusive to Leochess90. The Hound is a powerful piece for frontal attack but has low mobility and impact to the sides and backwards.  It can move one or two spots forward either in diagonal or straight ahead, and any piece that is within this range can be captured by the Hound; however, it can also move one or two spots to the sides or backwards, but in that case, it cannot capture enemy pieces. The Hound can't move diagonally to the back. The Hound is ideal to break the opponent's Pawn structure.
                    </p>

                    <h4>RULE NO. 9 MAJOR PIECES</h4>

                    <p>There are 4 major pieces in Leochess90:</p>

                    <p><span>•</span> Rooks</p>

                    <p><span>•</span> Panthers</p>

                    <p><span>•</span> Elephants</p>

                    <p><span>•</span> Queens</p>

                    <h4>9.1 THE ROOK</h4>

                    <p>
                    The Rook moves through the columns and rows with no range limit which is why it can occupy all the cells in the board. The Leochess90 Rooks mobility and possibilities emulate those of traditional chess rooks. Its reach makes it an important piece, specially in the mid-game and towards the end of the match.
                    </p>

                    <h4>9.2 THE PANTHER </h4>

                    <p>
                    The Panther is a major piece exclusive to Leochess 90 and can be considered an empowered Knight. It's the only major piece that can leap over other pieces.
                    </p>

                    <p>
                    Its movements are similar to the Knight’s, but it can move three spots along the columns or rows before moving sideways one spot. This can be visualized as a 6 or 8 square rectangle in which the Panther occupies one of the corners and moves to the opposite corner. This characteristic makes the Panther a menacing attack piece that can threat multiple pieces simultaneously.
                    </p>

                    <h4>9.3 THE ELEPHANT</h4>

                    <p>
                    The Elephant is a major piece exclusive to Leochess90 and can the considered a short-range Queen with a great capacity for attack. It can move one or two squares either in columns, rows or diagonals.
                    </p>

                    <h4>9.3.1 ELEPHANT’S IMMUNITY</h4>

                    <p>
                    The Elephant is immune to all pawn attacks, including the traditional Pawn as well as Rabbits and Squirrels, therefore the only pieces that can capture an Elephant are minor and major pieces. 
                    </p>

                    <h4>9.3.2 ELEPHANT’S ATTACK</h4>

                    <p>
                    The Elephant is a unique piece, its attack differs from that of the others: it can capture 2 enemy pieces simultaneously. In its two square trajectory in any direction, the Elephant can capture every enemy piece in its way. Of course, the Elephant can capture only one piece if necessary. 
                    </p>

                    <h4>9.4 THE QUEEN</h4>

                    <p>
                    The Queen is the most powerful piece in traditional chess and in Leochess90 since it can be moved both as a Bishop and as a Rook, moving indefinitely along rows, columns and diagonals. The only difference versus traditional chess is that in Leochess90 each player starts the game with two Queens.
                    </p>

                    <h4>RULE NO.10 SPECIAL PIECES</h4>

                    <p>In Leochess90 there are 2 special pieces:</p>

                    <p><span>•</span> The Lion</p>

                    <p><span>•</span> The King</p>

                    <h4>10.1 THE LION</h4>

                    <p>
                    The Lion is a unique piece that moves as a combination of a Bishop and a Rook, but with a limited reach. As a Rook it can move three spots along the rows and columns and as a Bishop it can move two spots diagonally in any direction. In that sense, the Lion could be considered a major piece except for the following singularities:
                    </p>

                    <h4>10.1.1 LION’S INMUNITY </h4>

                    <p>
                    The Lion is, along with the Elephant, the only piece that is immune to certain attacks; however, the Lion only has immunity regarding traditional Pawns and it can be captured by either Rabbits or Squirrels. 
                    </p>

                    <h4>10.1.2 LION’S CROWNING</h4>

                    <p>
                    The Lion is the only piece in the board, besides the pawns, that can be promoted.
                    </p>

                    <p>
                    If the Lion is crowned it is transformed into a King and the player immediately wins the game, unless the opponent can capture it in the next move. Having two Kings of the same color automatically finishes the match.
                    </p>

                    <h4>10.1.3 LIONS’S LOSS</h4>

                    <p>
                    If a player has its Lion captured during the game by an enemy piece (excluding pawns), the game is not yet lost since the King has not been eliminated. The only thing that is lost is the possibility of winning the game by crowning.
                    </p>

                    <h4>10.2 THE KING</h4>

                    <p>
                    The King is the most valuable piece in both traditional chess and Leochess90 since the objective of the game is to eliminate the opponent’s and keep yours safe. Its mobility is quite limited: it can only move one spot at a time in any direction, therefore it must always be protected by all the other pieces. 
                    </p>

                    <h4>10.2.1 CHECK</h4>

                    <p>
                    A King is in check when it is under attack by at least one enemy piece. When this happens, the player is obliged to announce that the King is in check. To avoid being in check, the player under attack must either move the King or block the check with some other piece or eliminate the threatening piece. 
                    </p>

                    <h4>10.2.2 CHECKMATE</h4>

                    <p>
                    If a player's King is placed in check and there is no legal move that player can make to escape or block the check, then the King is said to be checkmated, the game ends, and that player loses.
                    </p>

                    <h4>10.2.3 CASTLING</h4>

                    <p>
                    To protect the King, Leochess90 has 4 different types of castling, different from traditional chess where there’s only two kinds: the short and the long one. Castling can only happen between the King and a Rook when the pieces involved have not previously moved from their original setup.
                    </p>

                    <p>
                    Given the symmetry of the chessboard, in Leochess90 the King can do short and long castling to either side, whenever the following conditions are met:
                    </p>

                    <p>
                      <span>•</span> There must be no pieces between the King and the Rook, this means, all the squares between them must be vacant
                    </p>

                    <p>
                      <span>•</span> The King cannot end up in a square that is under attack by an enemy piece.
                    </p>

                    <p>
                    If the conditions are met, castling is allowed. 
                    </p>

                    <p>
                    Castling consists of moving the King two squares towards a Rook, then placing the Rook on the other side of the King, adjacent to it (short castling) or leaving an empty square between the King and the Rook (long castling). The pieces will move 2 or 3 squares to the sides, depending on whether it is a short or a long castling. 
                    </p>

                    <h4>10.2.4 STALEMATE</h4>

                    <p>
                    If the game reaches a situation in which the player can neither move the King to any square because it is threatened by enemy pieces nor move any other piece, but isn’t in check, it is said that the King is in stalemate. In such cases the game is automatically a draw.
                    </p>

                    <h4>RULE NO. 11 BEHAVIOR AND IRREGULARITIES</h4>

                    <p>
                    Leochess90 is not a game of chance, but a game full of knowledge and strategy where the winner is not the luckiest player but the most prepared. When a player is new to the game is usual for him/her to lose against experienced players. To learn the game it is necessary some humility to lose over and over again until a competitive level is acquired. But the reward is worth it because the fun is fantastic! 
                    </p>

                    <h4>11. 1 BEHAVIOR</h4>

                    <p>
                    Players must always respect their opponents and make only the legal moves stipulated in the rules for each piece. If either player considers that the match is so even that neither can beat the other, he/she can offer a draw to their opponent, offer that he/she can either accept or reject. If one player considers that the opponent has a much superior position and has no possibilities of winning the game, he/she can abandon the game by expressing his/her intentions and accepting defeat. 
                    </p>

                    <p>
                    At the end of the game, both players can shake hands as a sign of approval of what has happened on the chessboard. 
                    </p>

                    <h4>11.2 IRREGULARITIES</h4>

                    <p>
                    During the game, problems may arise that must be resolved with propriety. Such situations are:
                    </p>

                    <p>
                      <span>•</span> When a player moves a piece differently to what is characterized for that specific piece in the rules, the movement is illegal and the piece must return to its previous position. 
                    </p>

                    <p>
                      <span>•</span> If the King is in check, the player cannot move other pieces unless it is to protect the King and stop it being in check. 
                    </p>

                    <p>
                      <span>•</span> If player touches a piece, he/she is obliged to move that piece, otherwise it is considered an illegal move.
                    </p>

                    <p>
                      <span>•</span> If during the same match a player makes 3 illegal moves, he/she will automatically lose the game.
                    </p>

                    <h4>RULE NO.12 END GAME</h4>

                    <p>
                    At the end of the game there are 3 possibilities for each player: win, lose or draw.
                    </p>

                    <h4>12.1 WINNING THE GAME</h4>

                    <p>Wins the game the player that:</p>

                    <p>
                      <span>•</span> Checkmates the opponent’s King
                    </p>

                    <p>
                      <span>•</span> Crowns or promotes the Lion without the opponent being able to eliminate it immediately after the crowning.
                    </p>

                    <p>
                      <span>•</span> Has the opponent surrender, i.e. leave the game
                    </p>

                    <p>
                      <span>•</span> Has the opponent exceeding the regulation time on the clock (when playing under time control mode).
                    </p>

                    <h4>12.2 DRAW</h4>

                    <p>The game is a draw when:</p>

                    <p><span>•</span> The players have reached a common agreement. </p>

                    <p>
                      <span>•</span> The 90 turns are completed without any of the players being able to crown the Lion or checkmate their opponent.
                    </p>

                    <p><span>•</span> Either player has the King in stalemate.</p>

                    <p>
                      <span>•</span> Neither player has enough material at the end of the game to checkmate the enemy King with legal moves, which occurs when the only pieces left are:
                    </p>

                    <p>king against king;</p>
                    <p>king against king and a minor piece;</p>
                    <p>king against king and a traditional pawn (that can only promote to Bishop);</p>

                    <h4>12.3 LOSING THE GAME</h4>

                    <p>Loses the game the player that: </p>

                    <p>
                      <span>•</span> Expressly declares its wishes to abandon the game, considering his own inability to overcome the disadvantage.
                    </p>

                    <p>
                      <span>•</span> Under a time control mode, runs out on the clock.
                    </p>

                    <p><span>•</span> SHas his King checkmated by the opponent.</p>

                    <p>
                      <span>•</span> Has the opponent crown his Lion and finds no way to offset it.
                    </p>
                  </div>
                ) : (
                  <div className="site-heading">
                    <h1 className="titulo">Reglas</h1>
                    <div align="center" style={{marginBottom:"20px"}}>
                      <img src={serpentina4} style={{}}></img>
                    </div>


                    <h4>REGLA NO. 1 INICIO DEL JUEGO</h4>

                    <p>
                      El Leochess90 es un juego de ajedrez nuevo y ampliado con
                      relación al ajedrez tradicional, donde se enfrentan 2
                      jugadores, el primero juega con las piezas blancas y el
                      segundo con las piezas negras. Tiene 12 tipos diferentes
                      de piezas a diferencia de las 6 del juego tradicional.
                    </p>

                    <h4>1.1 EL TABLERO</h4>

                    <p>
                      El tablero del Leochess 90 consiste en un rectángulo casi
                      cuadrado, formado por 10 filas y 9 columnas, formando una
                      retícula que consta de 90 casillas. Este tablero es mayor
                      que el del ajedrez tradicional que consta de un cuadrado
                      de 8 filas y 8 columnas, formando una retícula de 64
                      casillas. Las casillas se alternan entre tonalidades
                      claras y oscuras, las casillas de color claro se llaman
                      blancas y las de color oscuro negras. Las 10 filas son
                      numeradas del 1 al 10 y las columnas reciben letras del
                      abecedario: A,B,C,D,E,F,G,H, I. Esta nomenclatura permite
                      que cada casilla tenga una denominación particular, por
                      ejemplo, A1, B6, E9, H10, etc.
                    </p>

                    <h4>1.2 LAS PIEZAS</h4>

                    <p>
                      Cada jugador recibe al inicio del juego 27 piezas. Las
                      piezas se dividen en Piezas mayores, Piezas menores y
                      peones, además del Rey y el León, que son piezas
                      especiales.
                    </p>

                    <p>
                      <span>•</span> En el Leochess 90, hay 3 tipos diferentes de peones; los
                      peones propiamente dichos, los conejos y las ardillas.
                    </p>

                    <p>
                      <span>•</span> También hay 3 tipos de Piezas menores, los Alfiles, los
                      Caballos y los Perros.
                    </p>

                    <p>
                      <span>•</span> Además, hay 4 tipos de Piezas mayores, las Torres, las
                      Panteras, los Elefantes y las Damas.
                    </p>

                    <p>
                      <span>•</span> Finalmente, existen 2 piezas únicas y especiales que son
                      el Rey y el León.
                    </p>

                    <p>
                      Al inicio de cada juego, los jugadores reciben 1 Rey, 1
                      León, 2 Damas, 2 Elefantes, 2 Panteras, 2 Torres, 2
                      Alfiles, 2 Perros, 2 Ardillas, 3 Conejos y 6 Peones.
                    </p>

                    <h4>1.2.1 VALOR DE LAS PIEZAS</h4>

                    <p>
                      Las piezas tienen un valor intrínseco, que permite a un
                      jugador determinar si le conviene realizar intercambios,
                      así, por ejemplo, no cambiará una torre por un caballo,
                      dado que ésta tiene un valor mayor que aquel, a menos que
                      la posición estratégica resulte más conveniente después
                      del intercambio. A esto se le denomina “sacrificio” de la
                      pieza o pérdida de calidad. Aunque en la tabla se indica
                      como 10 el máximo valor del Rey (que es la pieza más
                      valiosa), en realidad éste es invaluable, debido a que, si
                      se captura, se pierde la partida.
                    </p>

                    <h4>1.2.2 ACOMODO DE LAS PIEZAS</h4>

                    <p>
                      Al inicio del juego, el Rey blanco se acomodará en una
                      casilla clara en el centro de la primera fila y el Rey
                      negro en una casilla oscura en el centro de la décima
                      fila, frente a ellos se pondrán sus respectivos leones, el
                      resto de las piezas se deben acomodar como se indica en el
                      siguiente diagrama:
                    </p>
                    <img src={tablero} alt="" className="img-fluid"></img>
                    <br></br>
                    <br></br>
                    <h4>1.3 OBJETIVO DEL JUEGO</h4>

                    <p>
                      El objetivo del juego es ganar la partida, venciendo a
                      nuestro contrincante, lo cual puede ocurrir si capturamos
                      al Rey enemigo o bien coronamos nuestro León, llegando
                      hasta la última fila.
                    </p>

                    <h4>1.4 MOVIMIENTOS Y TURNOS.</h4>

                    <p>
                      Un movimiento consiste en colocar una pieza en una casilla
                      diferente, siguiendo las reglas de movimiento de cada una
                      de las piezas. Las piezas blancas inician la partida,
                      realizando un solo movimiento, después juegan las negras
                      haciendo lo propio. Cuando ambos jugadores han realizado
                      su primer movimiento, se ha realizado el primer turno. En
                      el Leochess 90 se juegan 90 turnos, es decir, 90
                      movimientos por cada jugador.
                    </p>

                    <h4>REGLA NO. 2 TURNOS Y CICLOS.</h4>

                    <p>
                      En el Leochess 90, al igual que en el ajedrez tradicional,
                      el primer jugador que lleva las piezas blancas, inicia la
                      partida y solo puede mover una pieza en cada turno. El
                      jugador de las negras responde moviendo a su vez una sola
                      pieza en su turno.
                    </p>

                    <h4>2.1 TURNOS</h4>

                    <p>
                      Cada turno, por lo tanto, consiste en 2 movimientos, el de
                      las blancas y el de las negras. Pero al completarse los
                      primeros 9 turnos, se termina el primer ciclo. Cada ciclo
                      contiene 9 turnos.
                    </p>

                    <h4>2.2 CICLOS</h4>

                    <p>
                      El juego consta de 10 ciclos, lo que significa que cada
                      jugador tiene 90 movimientos; si al finalizar los 90
                      movimientos de cada jugador, ninguno ha obtenido la
                      victoria, el juego se declara empatado o, como se dice en
                      el lenguaje del ajedrez, “tablas”.
                    </p>

                    <h4>2.3 DOBLE TURNO</h4>

                    <p>
                      Cuando los jugadores han completado el primer ciclo,
                      consistente en 9 movimientos cada uno, el jugador de las
                      negras tiene doble turno, ahora le corresponde tomar la
                      iniciativa del juego y como fue el último en mover en el
                      noveno turno, le corresponde salir primero en el segundo
                      ciclo; no obstante, no le es permitido mover la misma
                      pieza dos veces seguidas, por lo cual al inicio del
                      segundo ciclo (en el movimiento 10) deberá mover cualquier
                      pieza excepto la última que movió al final del primer
                      ciclo.
                    </p>

                    <p>
                      Cuando finaliza el segundo ciclo, le corresponde al
                      jugador de las piezas blancas tener doble turno, ya que
                      fue el último en mover en el segundo ciclo y ahora le
                      corresponde retomar la iniciativa del juego en el tercer
                      ciclo (movimientos 18 y 19).
                    </p>

                    <p>
                      Esto significa que el jugador de las blancas inicia
                      siempre en los ciclos nones y el jugador de las negras en
                      los ciclos pares. Cada jugador tiene la iniciativa durante
                      5 ciclos a lo largo de la partida.
                    </p>

                    <h4>REGLA NO. 3 DURACIÓN DE LA PARTIDA</h4>

                    <p>
                      El tiempo siempre es un factor en los juegos de ajedrez.
                      Desde luego, los jugadores se pueden poner de acuerdo con
                      relación al tiempo que le quieren destinar al juego, para
                      lo cual contarán con un reloj de ajedrez, o también jugar
                      a tiempo indefinido sin límite de tiempo y sin reloj; sin
                      embargo, para efectos de los torneos oficiales de Leochess
                      90, existen 3 modalidades de tiempo:
                    </p>

                    <p>
                      <span>•</span> El juego oficial estándar que tiene una duración de 90
                      minutos (45 para cada jugador).{" "}
                    </p>

                    <p>
                      <span>•</span> La partida rápida con duración de 60 minutos (30 minutos
                      para cada jugador)
                    </p>

                    <p>
                      <span>•</span> La partida relámpago o “Blitz” que tiene una duración de
                      30 minutos (15 minutos para cada jugador)
                    </p>

                    <p>
                      Si cualquier jugador excede su tiempo de juego,
                      automáticamente pierde la partida.
                    </p>

                    <h4>REGLA NO.4 LOS PEONES</h4>

                    <p>
                      En el Leochess 90, existen 3 tipos diferentes de peones:
                    </p>

                    <p><span>•</span> Los Peones</p>

                    <p><span>•</span> Los Conejos</p>

                    <p><span>•</span> Las Ardillas</p>

                    <h4>4.1 EL PEÓN</h4>

                    <p>
                      El peón, al igual que en el ajedrez tradicional, puede
                      iniciar su movimiento moviendo una o dos casillas hacia el
                      frente (en columnas) y posteriormente solo puede hacer un
                      solo movimiento hacia el frente, una sola casilla. El peón
                      (como cualquier otra pieza), cuando captura otra pieza se
                      dice que “se la come”. El peón solo come de lado en
                      diagonal avanzando una sola casilla y si frente a él se
                      encuentra cualquier otro peón o pieza, está impedido para
                      avanzar. Los peones nunca comen de frente.
                    </p>

                    <h4>4.2 EL CONEJO</h4>

                    <p>
                      El conejo es un peón potenciado (potenciado al cuadrado),
                      esto quiere decir que, a diferencia del peón tradicional,
                      puede mover 2 casillas hacia el frente en cualquier
                      momento de la partida, también come de lado en diagonal,
                      pero no solo eso, sino que se puede mover hacia el frente
                      en diagonal una sola casilla, aunque no haya comido, si
                      esta casilla está vacía.
                    </p>

                    <h4>4.3 LA ARDILLA</h4>

                    <p>
                      La ardilla también es un peón potenciado (potenciado al
                      cubo), esto quiere decir que la ardilla puede mover 3
                      casillas hacia el frente en cualquier momento, siempre y
                      cuando alguna otra pieza no se lo impida. La ardilla, al
                      igual que el conejo, come en diagonal pero también se
                      puede mover en diagonal una casilla sin necesidad de
                      comer. Adicionalmente, la ardilla se puede mover hacia los
                      lados una casilla, pero en este caso no puede comer.
                    </p>

                    <p>
                      Los peones (peones, conejos y ardillas) nunca pueden
                      retroceder.
                    </p>

                    <p>
                      Los peones, como es lógico, tienen jerarquías, esto quiere
                      decir que el peón de menor jerarquía es el peón
                      propiamente dicho, seguido de el conejo y la ardilla, que
                      es el peón de mayor jerarquía.
                    </p>

                    <h4>REGLA NO. 5 CORONACIÓN DE LOS PEONES</h4>

                    <p>
                      Cuando los peones logran llegar hasta la última fila en el
                      campo enemigo (fila 10 para las blancas y fila 1 para las
                      negras) pueden “Coronar” o promover, esto quiere decir que
                      cambian su naturaleza de peón y se transforman en una
                      pieza más poderosa. En el ajedrez tradicional que solo
                      tiene un tipo de peón, cuando éste corona, se puede
                      convertir en cualquier pieza menor o mayor (dama, torre,
                      alfil o caballo); pero, dado que en el Leochess 90 hay 3
                      tipos diferentes de peones, estos solo coronan de acuerdo
                      con sus jerarquías:
                    </p>

                    <p><span>•</span> El Peón se corona en Alfil</p>
                    <p><span>•</span> El Conejo se corona en Torre</p>
                    <p><span>•</span> La Ardilla se corona en Dama.</p>

                    <h4>REGLA NO. 6 COMER AL PASO</h4>

                    <p>
                      Los peones pueden comer a otros peones al paso de acuerdo
                      con sus jerarquías.
                    </p>

                    <p>
                      El peón solo puede comer al paso a otros peones, esto solo
                      ocurre cuando en su primer movimiento un peón avanza 2
                      casillas. La casilla libre que queda después del avance,
                      puede ser ocupada por otro peón, si este se encuentra en
                      condiciones de ocuparla al moverse en diagonal hacia esa
                      casilla. En el caso de las piezas blancas, sus peones solo
                      pueden comer al paso si se encuentran en la fila 7 y
                      pueden ocupar la fila 8 después del avance de 2 casillas
                      del peón negro. En el caso de las piezas negras, estas
                      solo pueden comer al paso a otros peones si se encuentran
                      en la fila 5 y pueden ocupar la fila 4 después del avance.
                      Comer al paso es siempre opcional.
                    </p>

                    <p>
                      Los conejos y las ardillas también pueden comer al paso a
                      los peones si están en las mismas condiciones. Los peones
                      no pueden comer al paso a los conejos y las ardillas,
                      porque estas son de mayor jerarquía.
                    </p>

                    <p>
                      Los conejos pueden comer al paso a otros conejos, esto
                      ocurre cuando un conejo avanza 2 casillas y el conejo
                      enemigo puede ocupar la casilla libre después del avance.
                    </p>

                    <p>
                      Esto puede ocurrir en cualquier lugar del tablero. Los
                      conejos no pueden comer al paso a las ardillas, pero éstas
                      si pueden comer al paso a los conejos si están en las
                      mismas condiciones.
                    </p>

                    <p>
                      Las ardillas pueden comer al paso a otras ardillas. Dado
                      que las ardillas pueden desplazarse hasta 3 casillas, esto
                      significa que dejan no uno sino 2 casillas libres al
                      realizar el avance. SI una ardilla enemiga puede ocupar
                      alguna de esas 2 casillas libres, puede comer al paso a la
                      ardilla enemiga. Esto ocurre en cualquier parte del
                      tablero.
                    </p>

                    <h4>REGLA NO. 7 COMER AL SALTO</h4>

                    <p>
                      En el Leochess 90, además de comer al paso, los conejos y
                      las ardillas también pueden comer al salto. Comer al salto
                      significa que si un peón de menor jerarquía está frente a
                      otro de mayor jerarquía, éste puede saltar hacia el frente
                      y comerlo, siempre y cuando la casilla que está detrás del
                      peón de menor jerarquía se encuentre libre. Obviamente el
                      peón no puede comer al salto, dado que no existe ninguna
                      pieza de menor jerarquía; sin embargo, los conejos pueden
                      comer al salto a los peones y las ardillas pueden comer al
                      salto a los conejos y a los peones.
                    </p>

                    <h4>REGLA NO. 8 LAS PIEZAS MENORES</h4>

                    <p>En el Leochess 90 hay 3 piezas menores, éstas son:</p>

                    <p><span>•</span> El Alfil</p>
                    <p><span>•</span> El Caballo</p>
                    <p><span>•</span> El perro</p>

                    <h4>8.1 EL ALFIL</h4>

                    <p>
                      El Alfil es la más poderosa de las piezas menores, ya que
                      domina las diagonales de su color en todo el tablero. El
                      Alfil del Leochess 90 se mueve igual que en el ajedrez
                      tradicional; sin embargo, existe una notable diferencia
                      respecto a este juego, ya que en él, los alfiles con
                      siempre de distinto color, en cambio, en el Leochess 90
                      los alfiles son siempre del mismo color, esto quiere decir
                      que las piezas blancas dominan las casillas ubicadas en
                      las diagonales blancas y las piezas negras dominan las
                      diagonales negras, dándole un sentido estratégico
                      diferente al juego. La única forma de obtener un alfil de
                      diferente color es coronando un peón en una casilla de
                      color diferente al de los respectivos alfiles.
                    </p>

                    <h4>8.2 EL CABALLO</h4>

                    <p>
                      El caballo es la única pieza menor que puede “saltar”
                      sobre otras piezas. Su movimiento consiste en avanzar 2
                      casillas sobre filas y columnas y después moverse hacia
                      una casilla que se encuentre al lado de la segunda, esto
                      se puede visualizar como un rectángulo de 6 casillas,
                      donde el caballo ocupa una de las esquinas y se mueve
                      hacia la esquina contraria.
                    </p>

                    <p>
                      Los caballos se mueven igual que en el ajedrez tradicional
                      y son ideales para atacar simultáneamente a 2 o más piezas
                      que se encuentren en su zona de influencia.
                    </p>

                    <h4>8.3 EL PERRO</h4>

                    <p>
                      El perro es la única pieza menor que existe en el Leochess
                      90, pero no en el ajedrez tradicional. El perro es una
                      pieza poderosa en el ataque frontal, pero de poca
                      capacidad de ataque y movilidad hacia los lados y hacia
                      atrás. Su movimiento consiste en ocupar una o dos casillas
                      hacia el frente, tanto en columnas como en diagonales, y
                      cualquier pieza que se encuentre en este rango de acción
                      puede ser comida por el perro; sin embargo, también se
                      puede mover una o dos casillas hacia los lados o hacia
                      atrás, pero en este caso sin poder comer. No se puede
                      mover en diagonal hacia atrás. El perro es ideal para
                      romper la estructura de peones del jugador enemigo.
                    </p>

                    <h4>REGLA NO. 9 LAS PIEZAS MAYORES</h4>

                    <p>En el Leochess 90 hay 4 piezas mayores que son:</p>

                    <p><span>•</span> La Torre</p>

                    <p><span>•</span> La Pantera</p>

                    <p><span>•</span> El Elefante</p>

                    <p><span>•</span> La Dama</p>

                    <h4>9.1 LA TORRE</h4>

                    <p>
                      La Torre se mueve a lo largo de las columnas y las filas,
                      sin límite de alcance, es por ello que puede ocupar todas
                      las casillas del tablero. Su capacidad y movilidad es
                      igual a la del ajedrez tradicional. Su gran alcance la
                      hace una pieza muy poderosa, sobre todo en el juego medio
                      y hacia el final de la partida.
                    </p>

                    <h4>9.2 LA PANTERA</h4>

                    <p>
                      La pantera es una pieza única del Leochess 90 y se puede
                      considerar como un caballo potenciado. Es la única pieza
                      mayor que puede saltar sobre el resto de las piezas.
                    </p>

                    <p>
                      Su movimiento puede ser igual al del caballo, pero
                      adicionalmente se puede mover tres casillas a lo largo de
                      filas y columnas y después moverse hacia un lado de la
                      tercera, esto se puede visualizar como un rectángulo de 6
                      o de 8 casillas, donde la pantera ocupa una de las
                      esquinas y se mueve hacia la esquina contraria. Esta
                      característica le permite a la pantera ser una poderosa
                      pieza de ataque que puede amenazar a muchas piezas
                      simultáneamente.
                    </p>

                    <h4>9.3 EL ELEFANTE</h4>

                    <p>
                      El Elefante es una pieza única del Leochess 90 y se puede
                      considerar como una dama de corto alcance pero de mayor
                      capacidad de ataque. Su movimiento consiste en mover una o
                      dos casillas, tanto en columnas y filas como en
                      diagonales.
                    </p>

                    <h4>9.3.1 INMUNIDAD DEL ELEFANTE</h4>

                    <p>
                      El Elefante es inmune al ataque de todos los peones, tanto
                      del peón propiamente dicho, como del conejo y la ardilla,
                      por lo tanto, las únicas piezas que pueden comer a un
                      elefante son las piezas menores y mayores.
                    </p>

                    <h4>9.3.2 ATAQUE DEL ELEFANTE</h4>

                    <p>
                      El Elefante es la única pieza del Leochess 90 cuyo ataque
                      es diferente que el resto de las piezas, ya que puede
                      comer a 2 piezas simultáneamente, esto ocurre debido a que
                      en su trayecto de 2 casillas en cualquier dirección, puede
                      arrasar con todo lo que esté en su camino, excepto si hay
                      alguna pieza propia, es decir, solo si se trata de peones
                      o piezas enemigas.
                    </p>

                    <h4>9.4 LA DAMA</h4>

                    <p>
                      La dama es la pieza más poderosa del Leochess 90, al igual
                      que del ajedrez tradicional, ya que se puede mover tanto
                      como Alfil como Torre, desplazándose indefinidamente tanto
                      en columnas y filas como en diagonales. La única
                      diferencia respecto al ajedrez tradicional, es que en éste
                      cada jugador cuenta con una sola dama, en tanto que en el
                      Leochess 90 cada jugador inicia el juego con 2 damas.
                    </p>

                    <h4>REGLA NO.10 LAS PIEZAS ESPECIALES</h4>

                    <p>En el Leochess 90 hay 2 piezas únicas y especiales:</p>

                    <p><span>•</span> El León</p>

                    <p><span>•</span> El Rey</p>

                    <h4>10.1 EL LEÓN</h4>

                    <p>
                      El León es una pieza única que se mueve como una
                      combinación de Alfil y Torre, pero de alcance limitado.
                      Como Torre mueve 3 casillas a lo largo de filas o columnas
                      y como alfil mueve 2 casillas en diagonal en cualquier
                      dirección. En este sentido, el león se podría considerar
                      también como una pieza mayor, excepto por algunas
                      particularidades que se mencionan enseguida.
                    </p>

                    <h4>10.1.1 INMUNIDAD DEL LEÓN</h4>

                    <p>
                      El León es, junto con el Elefante, la única pieza inmune a
                      algunos ataques; sin embargo, en el caso del León, la
                      inmunidad es únicamente respecto a los peones propiamente
                      dichos, pudiendo ser comido por conejos y ardillas.
                    </p>

                    <h4>10.1.2 CORONACIÓN DEL LEÓN</h4>

                    <p>
                      El León es la única pieza del tablero, además de los
                      peones, que puede coronar.
                    </p>

                    <p>
                      Si el León corona, se transforma en Rey y automáticamente
                      gana el juego, a menos que en el siguiente movimiento el
                      otro jugador pueda comerlo. Tener 2 reyes del mismo color
                      hace que la partida se termine.
                    </p>

                    <h4>10.1.3 PÉRDIDA DEL LEÓN</h4>

                    <p>
                      Si un jugador pierde a su León durante el juego por haber
                      sido comido por alguna pieza enemiga (excepto el peón), el
                      juego aún no está perdido, ya que no se ha eliminado al
                      Rey, lo único que en realidad se pierde es la posibilidad
                      de ganar el juego coronando.
                    </p>

                    <h4>10.2 EL REY</h4>

                    <p>
                      El Rey es la pieza más valiosa del ajedrez y del Leochess
                      90, ya que el objetivo del juego es eliminarlo, y si el
                      oponente lo consigue, se pierde el juego. Su movilidad es
                      muy limitada, ya que solo puede mover una casilla en
                      cualquier dirección, razón por la cual debe ser siempre
                      protegido por el resto de las piezas.
                    </p>

                    <h4>10.2.1 JAQUE AL REY</h4>

                    <p>
                      Si alguna pieza enemiga ataca al Rey, el jugador está
                      obligado a anunciar que el Rey se encuentra en Jaque. Para
                      evitar el jaque, el jugador amenazado debe o bien mover su
                      Rey o bloquear el jaque con alguna de sus piezas o
                      eliminar a la pieza que está realizando el Jaque.
                    </p>

                    <h4>10.2.2 JAQUE MATE</h4>

                    <p>
                      Si al realizar el jaque, el Rey no tiene escapatoria y no
                      se puede cubrir, defender o eliminar a la pieza enemiga,
                      el Rey se encuentra en Jaque Mate y esto significa que se
                      ha perdido el juego o la batalla.
                    </p>

                    <h4>10.2.3 ENROQUE DEL REY</h4>

                    <p>
                      Para dar protección al Rey, en el Leochess 90 hay 4 tipos
                      de enroque, a diferencia del ajedrez tradicional donde
                      solo hay 2 enroques, el enroque corto y el enroque largo.
                      El enroque solo se puede dar entre el Rey y alguna de sus
                      Torres, siempre y cuando ninguna de ambas piezas se haya
                      movido de su posición original.
                    </p>

                    <p>
                      En el Leochess 90, dada la simetría que existe en el
                      tablero, el Rey se puede enrocar en corto y en largo,
                      tanto a la derecha como a la izquierda y el enroque se
                      puede dar únicamente si se cumplen las siguientes 2
                      condiciones:
                    </p>

                    <p>
                      <span>•</span> Que entre el Rey y la Torre no se encuentre ninguna
                      pieza, esto es, que todas las casillas entre la Torre y el
                      Rey se encuentren libres.
                    </p>

                    <p>
                      <span>•</span> Que al realizar el enroque el Rey pueda quedar en jaque
                      por alguna pieza enemiga.
                    </p>

                    <p>
                      Si se cumplen estas condiciones, el Rey se puede enrocar.
                    </p>

                    <p>
                      El enroque consiste en acercar alguna de las Torres a la
                      casilla contigua al Rey, y que éste salta sobre la Torre
                      ocupando o bien la casilla contigua (enroque corto) o bien
                      dejando una casilla libre entre la Torre y el Rey (enroque
                      largo), moviéndose 2 o 3 casillas hacia los lados, según
                      sea enroque corto o largo.
                    </p>

                    <h4>10.2.4 REY AHOGADO</h4>

                    <p>
                      Si el juego llega a una posición en la cual el Rey no se
                      puede mover hacia ninguna casilla porque están amenazadas
                      por las piezas enemigas y tampoco puede mover ninguna de
                      sus otras piezas, pero no se encuentra amenazado por algún
                      tipo de jaque, se dice que el Rey está ahogado, y en ese
                      caso la partida queda automáticamente empatada, es decir,
                      se llega a una posición de tablas.
                    </p>

                    <h4>REGLA NO. 11 CONDUCTA E IRREGULARIDADES</h4>

                    <p>
                      El Leochess 90, no es un juego de azar, sino un juego de
                      conocimiento y estrategia, donde no gana el jugador que
                      tiene más suerte, sino aquel que tiene mayor conocimiento
                      del juego y establece las mejores estrategias. Cuando un
                      principiante se inicia en el juego, normalmente pierde
                      sistemáticamente ante jugadores que tienen un mayor nivel.
                      Para aprender el juego se requiere ser humilde y aprender
                      a perder muchas veces, hasta que se va adquiriendo un
                      nivel competitivo, pero la recompensa es muy grande, ya
                      que la diversión es mayúscula.
                    </p>

                    <h4>11. 1 CONDUCTA</h4>

                    <p>
                      Los jugadores deben respetar siempre al oponente y
                      realizar únicamente movimientos que se consideren legales,
                      de acuerdo con las reglas de movimiento de cada pieza. Si
                      alguno de los jugadores considera que la partida es tan
                      equilibrada que ninguno podrá vencer a su oponente, puede
                      ofrecer “tablas” a su oponente, el cual podrá aceptar o
                      rechazar la oferta.
                    </p>

                    <p>
                      Si considera que su oponente está en una posición muy
                      superior y no tendrá posibilidades de ganar, puede
                      abandonar la partida, expresando su intención al oponente
                      y aceptando su derrota.{" "}
                    </p>

                    <p>
                      Al finalizar la partida, ambos jugadores pueden estrechar
                      sus manos, como gesto de aprobación de lo que ha sucedido
                      durante el desarrollo de la partida.
                    </p>

                    <h4>11.2 IRREGULARIDADES</h4>

                    <p>
                      Durante el desarrollo del juego, pueden surgir algunos
                      problemas que deben resolverse con una adecuada conducta,
                      tales situaciones son las siguientes:
                    </p>

                    <p>
                      <span>•</span> Cuando un jugador mueve una pieza de manera diferente a
                      las reglas de movimiento de cada pieza, se considera un
                      movimiento ilegal y se debe regresar a la posición
                      anterior.
                    </p>

                    <p>
                      <span>•</span> Si el Rey está en jaque, no se pueden realizar
                      movimientos con otras piezas, a menos que sean para evitar
                      que el Rey siga permaneciendo en jaque.
                    </p>

                    <p>
                      <span>•</span> Si un jugador toda una pieza, está obligado a mover
                      dicha pieza, de lo contrario se considera un acto ilegal.
                    </p>

                    <p>
                      <span>•</span> Si en una misma partida un jugador realiza 3 movimientos
                      ilegales, pierde la partida.
                    </p>

                    <h4>REGLA NO.12 FIN DEL JUEGO</h4>

                    <p>
                      Al final del juego existen 3 posibilidades, que se haya
                      ganado la partida, que se hayan realizado tablas (empate)
                      o bien que se haya perdido.
                    </p>

                    <h4>12.1 GANAR LA PARTIDA</h4>

                    <p>Gana la partida el jugador que:</p>

                    <p>
                      <span>•</span> Haya conseguido hacer jaque mate al rey de su oponente
                    </p>

                    <p>
                      <span>•</span> Haya logrado coronar o promover a su propio León sin que
                      el oponente lo haya podido eliminar inmediatamente después
                      de la coronación.
                    </p>

                    <p>
                      <span>•</span> Su oponente se haya rendido, es decir, haya abandonado
                      la partida.
                    </p>

                    <p>
                      <span>•</span> Su oponente haya excedido el tiempo reglamentario en su
                      reloj, si se juega bajo la modalidad de control de tiempo.
                    </p>

                    <h4>12.2 EMPATAR LA PARTIDA</h4>

                    <p>La partida se empata o bien, se declara “tablas” si:</p>

                    <p><span>•</span> Los jugadores lo han acordado de mutuo acuerdo.</p>

                    <p>
                      <span>•</span> Se completan los 90 turnos sin que ninguno de los
                      jugadores haya podido coronar a su León o haber dado jaque
                      mate a su oponente.
                    </p>

                    <p><span>•</span> El Rey propio o del oponente se encuentra ahogado.</p>

                    <p>
                      <span>•</span> Ninguno de los dos jugadores tiene material suficiente
                      al final del juego para dar jaque mate con movimientos
                      legales al Rey enemigo, lo cual ocurre cuando:
                    </p>

                    <p>
                      Solo quedan los 2 reyes; queda un Rey contra Rey y una
                      pieza menor; queda un Rey contra Rey y un peón propiamente
                      dicho, ya que solo puede coronar en Alfil.
                    </p>

                    <h4>12.3 PERDER LA PARTIDA</h4>

                    <p>Pierde la partida el jugador que:</p>

                    <p>
                      <span>•</span> Lo declara expresamente al abandonar la partida, por
                      considerar que su situación ya no le permite poder
                      remontar la desventaja.
                    </p>

                    <p>
                      <span>•</span> Si se juega bajo modalidad de control de tiempo y se
                      agota el tiempo en el reloj.
                    </p>

                    <p><span>•</span> Su Rey recibe jaque mate por parte del oponente</p>

                    <p>
                      <span>•</span> El contrincante corona su León y no tiene forma de
                      impedirlo.
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </header>
      </section>

      <MyFooter />
    </section>
  );
}
