import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Navbar, NavDropdown, Nav } from "react-bootstrap";

import "./fonts/fontawesome-all.min.css";
import "./fonts/ionicons.min.css";
import "./fonts/line-awesome.min.css";
import "./fonts/fontawesome5-overrides.min.css";
import "./css/Social-Icons.css";

import "./css/menu.css";
import "./css/encabezado.css";

import logo from "./images/logo-megachess.png";
import { MyNavbar } from "./Navbar";
import { MyFooter } from "./Footer";

export function Comprar() {
  //idioma
  var idioma_seleccionado = localStorage.getItem("idioma");
  if (idioma_seleccionado == null) {
    idioma_seleccionado = "Español";
    localStorage.setItem("idioma", "Español");
  }
  const [idioma, setIdioma] = useState(idioma_seleccionado);
  //fin idioma

  return (
    <section className="fondo_principal">
      <MyNavbar />

      <section className="encabezado">
        <header className="fondo2">
          <Container>
            <Row>
              <Col xs={12} style={{ padding: 0 }}>
                <div className="site-heading">
                  {idioma == "English" ? <h1>Shop</h1> : <h1>Comprar</h1>}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      </section>

      <MyFooter />
    </section>
  );
}
