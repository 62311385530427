import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useEffect, useState } from "react";

import logo from "./images/logo-megachess.png";
import { useOnlineState } from "./hooks/useOnlineState";
import firebase from 'firebase';


export function MyNavbar() {
    const isOnline = useOnlineState();
    
    var idioma_seleccionado = localStorage.getItem("idioma");
    if(idioma_seleccionado == null){
        idioma_seleccionado = 'Español';
        localStorage.setItem("idioma", "Español");
    }
    const [idioma, setIdioma] = useState(idioma_seleccionado);
    
    const idiomaEspañol = () => {
        setIdioma('Español');    
        localStorage.setItem('idioma', 'Español');
        window.location.reload(false);
    }    
    const idiomaEnglish = () => {
        setIdioma('English');    
        localStorage.setItem('idioma', 'English');
        window.location.reload(false);
    }  
    
    
    
    return (
        <Navbar expand="md" className="fondo_principal">
        <Container>
        <Navbar.Brand href="/">
        <img src={logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        
        {idioma == 'Español' ? (
                <Nav className="mr-auto">    
                <Nav.Link href="/">Inicio</Nav.Link>
                <NavDropdown title="Aprender" id="basic-nav-dropdown">
                <NavDropdown.Item href="/reglas">Reglas</NavDropdown.Item>
                <NavDropdown.Item href="/movimientos">Movimientos</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/lobby">Sala de Juegos</Nav.Link>
                {/*<Nav.Link href="/blog">Blog</Nav.Link>*/}
               
                <Nav.Link onClick={idiomaEnglish}>English</Nav.Link>
                </Nav>
                ) : (
                    <Nav className="mr-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <NavDropdown title="Learn" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/reglas">Rules</NavDropdown.Item>
                    <NavDropdown.Item href="/movimientos">Moves</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/lobby">Game room</Nav.Link>
                    {/*<Nav.Link href="/blog">Blog</Nav.Link>*/}
                    
                    <Nav.Link onClick={idiomaEspañol}>Español</Nav.Link>
                    </Nav>
                    
                    )
                }
                {isOnline ? (<Nav.Link onClick={() => { firebase.auth().signOut(); window.location = "/lobby" }}>{idioma == "English" ? "Sign out":"Cerrar sesion"}</Nav.Link>) : (<Nav.Link href="/login">{idioma == "English" ? "Sign in":"Iniciar sesion"}</Nav.Link>)}
                
                
                </Navbar.Collapse>
                </Container>
                </Navbar>
                );
            }
            