import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Navbar, NavDropdown, Nav } from 'react-bootstrap';


import "./fonts/fontawesome-all.min.css";
import "./fonts/ionicons.min.css";
import "./fonts/line-awesome.min.css";
import "./fonts/fontawesome5-overrides.min.css";
import "./css/Social-Icons.css";

import "./css/menu.css";
import "./css/encabezado.css";
import "./Movimientos.css";


import { MyNavbar } from "./Navbar";
import { MyFooter } from "./Footer";
import rey from "./images/tableros/rey.png";
import mov_rey from "./images/tableros/mov-rey.png";
import dama from "./images/tableros/dama.png";
import mov_dama from "./images/tableros/mov-dama.png";
import elefante from "./images/tableros/elefante.png";
import mov_elefante from "./images/tableros/mov-elefante.png";
import pantera from "./images/tableros/pantera.png";
import mov_pantera from "./images/tableros/mov-pantera.png";
import torre from "./images/tableros/torre.png";
import mov_torre from "./images/tableros/mov-torre.png";
import leon from "./images/tableros/leon.png";
import mov_leon from "./images/tableros/mov-leon.png";
import alfil from "./images/tableros/alfil.png";
import mov_alfil from "./images/tableros/mov-alfil.png";
import perro from "./images/tableros/perro.png";
import mov_perro from "./images/tableros/mov-perro.png";
import caballo from "./images/tableros/caballo.png";
import mov_caballo from "./images/tableros/mov-caballo.png";
import ardilla from "./images/tableros/ardilla.png";
import mov_ardilla from "./images/tableros/mov-ardilla.png";
import conejo from "./images/tableros/conejo.png";
import mov_conejo from "./images/tableros/mov-conejo.png";
import peon from "./images/tableros/peon.png";
import mov_peon from "./images/tableros/mov-peon.png";

import icon_rey from "./images/icon_rey.png";
import icon_alfil from "./images/icon_alfil.png";
import icon_ardilla from "./images/icon_ardilla.png";
import icon_caballo from "./images/icon_caballo.png";
import icon_conejo from "./images/icon_conejo.png";
import icon_dama from "./images/icon_dama.png";
import icon_elefante from "./images/icon_elefante.png";
import icon_leon from "./images/icon_leon.png";
import icon_pantera from "./images/icon_pantera.png";
import icon_peon from "./images/icon_peon.png";
import icon_perro from "./images/icon_perro.png";
import icon_torre from "./images/icon_torre.png";

import comer from "./images/comer.png";
import movimiento from "./images/movimiento.png";
import serpentina3 from "./images/serpentina-3.png";



export function Movimientos() {
     //idioma
     var idioma_seleccionado = localStorage.getItem("idioma");
    if (idioma_seleccionado == null) {
        idioma_seleccionado = "Español";
        localStorage.setItem("idioma", "Español");
    }
    const [idioma, setIdioma] = useState(idioma_seleccionado);
    //fin idioma
        
    const imagenes = [rey,dama,elefante,pantera,torre,leon,alfil,perro,caballo,ardilla,conejo,peon];

    const titulos = ["EL REY","LA DAMA","EL ELEFANTE","LA PANTERA","LA TORRE","EL LEÓN","EL ALFIL","EL PERRO","EL CABALLO","LA ARDILLA","EL CONEJO","EL PEÓN"];
    const titulos_en = ["THE KING","THE QUEEN","THE ELEPHANT","THE PANTER","THE ROOK","THE LION","THE BISHOP","THE HOUND","THE HORSE","THE SQUIRREL","THE RABBIT","THE PAWN"];

    const tableros = [mov_rey,mov_dama,mov_elefante,mov_pantera,mov_torre,mov_leon,mov_alfil,mov_perro,mov_caballo,mov_ardilla,mov_conejo,mov_peon];
    const textos = [
    "El rey es la pieza más importante. Solo hay un rey. En el Leochess 90, el monarca se mueve igual que en el ajedrez tradicional, es decir, solo puede mover una casilla en cualquier dirección. Si se captura al rey enemigo (se le da jaque mate) se gana el juego. Si el enemigo captura nuestro rey, perdemos el juego. El rey se puede 'enrocar' como en el ajedrez tradicional, pero la diferencia es que no hay enroque corto o largo, sino enroque derecho o izquierdo, y además, el enroque consiste en acercar la torre (derecha o izquierda) hacia el rey y 'saltar' sobre ella, una o dos casillas, a diferencia del enroque del ajedrez tradicional. El rey puede 'optar' por ocupar cualquiera de las 2 casillas entre la torre y el rey. Si, por ejemplo, se enroca del lado derecho en g1, se llama enroque derecho corto y si se enroca en h1, se llama enroque derecho largo. (hay 4 posibles enroques) este movimiento solo se da en la fila 1 para las blancas y en la fila 10 para las negras. El rey solo se puede enrocar si no hay ninguna pieza entre la torre y el rey. Si alguna pieza enemiga interfiere en cualquiera de las casillas del enroque, el rey no se puede enrocar. Si un león corona, se transforma en rey y al haber 2 reyes gana la partida. Después de la coronación del león, automáticamente se gana el juego, a menos que en el siguiente turno el contrincante pueda matarlo o eliminarlo. El rey puede comer o ser ser comido por cualquier pieza.",
    "Después del rey, la dama es la pieza más valiosa del Leochess 90. Cada jugador cuenta con 2 damas. La dama es la pieza de defensa y ataque más poderosa en el tablero. Las damas mueven en diagonal y en columnas o filas sin límite de casillas. La dama del leochess 90 mueve igual que la dama del ajedrez tradicional. La única diferencia es que, a diferencia del ajedrez tradicional que cuenta con una sola dama, en el Leochess 90 hay dos damas. Si una ardilla 'corona', se transforma en dama. Solo las ardillas se pueden coronar en damas. Las damas pueden comer o ser comidas por cualquier pieza.",       
    "El elefante es una pieza mayor y tiene el mismo valor que la pantera. Cada jugador cuenta con 2 elefantes. Mueve como dama, pero su alcance es de tan solo dos casillas. El elefante se pueden desplazar libremente 2 casillas en cualquier dirección, exepto cuando interfiere una pieza del propio color. El elefante es la única pieza que se puede comer a 2 piezas enemigas simultaneamente en su camino, bien sea en diagonales o en filas y columnas. Las únicas piezas que pueden destruir al elefante son las piezas mayores y medias, ya que es inmune al ataque de piezas menores (peones, ardillas y conejos) ninguna pieza se puede coronar en elefante (son únicos). El elefante es una pieza muy útil para romper la estructura de peones. Los elefantes son muy poderosos, tanto en el ataque como en la defensa, sobre todo en juegos cerrados.",
    "La pantera tiene un valor equivalente al del elefante, ambas son piezas mayores. Cada jugador cuenta con 2 panteras. Son, después del rey, el león, las damas y las torres, las piezas más valiosas. Las panteras mueven igual que los caballos, pero su alcance es aún mayor, porque también pueden alcanzar la cuarta casilla contando...1,2,3 y al lado. Tanto en filas como en columnas. La pantera puede, al igual que los caballos saltar sobre las demás piezas y, por ello, es ideal para atacar a varias piezas simultáneamente. Ninguna pieza se puede coronar en pantera (son únicas) las panteras son muy potentes en los finales de partida y en juegos abiertos porque pueden dar dobletes, tripletes, etc. La pantera puede comer o ser comidas por cualquier pieza, no es inmune, como el elefante, al ataque de piezas menores.",
    "La torre, por su gran poder, tiene un valor equivalente al del león. Cada jugador cuenta con 2 torres. La torre se mueve igual que en el ajedrez tradicional. Las torres mueven a lo largo de todas las casillas de filas y columnas. La torre es una pieza muy poderosa en los finales, cuando quedan pocas piezas, por su gran alcance. A diferencia del león, la torre no pueden coronar y ganar la partida. Si un conejo corona, se transforma en torre. La torre puede comer o ser comida por cualquier pieza",  
    "El león es, después del rey y las damas, la pieza más importante del Leochess 90. Su valor es equivalente al de las torres, aunque éstas no pueden ganar por si mismas el juego. El león mueve como las torres, pero su alcance máximo es de tan solo 3 casillas, pero también mueve como los alfiles con un alcance de tan solo 2 casillas en cualquier diagonal. Si el león se corona, se transforma en 'el rey león' y gana el juego, a menos que el oponente pueda comer al león justo en su siguiente turno. (Movimiento siguiente a haberse coronado). El león es inmune al ataque de los peones. Si un jugador pierde a su león, no pierde el juego, pero pierde la posibilidad de ganar 'coronando'.",
    "El alfil es la pieza media más valiosas por su gran alcance. Cada jugador cuenta con 2 alfiles del mismo color. Los alfiles mueven igual que en el ajedrez tradicional, esto quiere decir que los alfiles se mueven a lo largo de las diagonales sin límites. Si un peón 'corona', se transforma en alfil. El es una pieza ideal para finales de partida y juegos abiertos por su alcance. También es importante para reforzar la estructura de peones. En el Leochess 90, cada jugador cuenta con 2 alfiles del mismo color, que se mueven por las diagonales de su propio color, lo cual le dá una ventaja estratégica sobre esas diagonales, ya que los alfiles enemigos son de distinto color. La única forma de obtener una pareja de alfiles de distinto color es coronando. El alfil puede comer o ser comidos por cualquier pieza.",
    "El perro es la pieza media de menor valor. Su movimiento hacia el frente es como el de una torre o un alfil, moviendo tan solo 2 casillas. También se puede mover como torre hacia los lados y atrás dos casillas, pero sin poder comer. No se pueden mover hacia atrás en diagonal. El perro puede comer piezas que se encuentren frente a ellos a diferencia de las piezas menores, que solo pueden comer en diagonal, pero no frente a ellos. El perro (como la ardilla) tampoco puede comer hacia los lados, pero si puede deslpazarse 2 casillas en esa dirección. El perro es ideal para romper la estructura de peones del enemigo. El perro puede comer o ser comido por cualquier pieza. ",
    "El caballo es una pieza media, su poder es intermedio entre el perro y el alfil. Cada jugador cuenta con 2 caballos. Los caballos mueven igual que en el ajedrez tradicional, esto es, contando 1 y 2 sobre diagonales y columnas y después moviendo al lado. El caballo, junto con la pantera, es la única pieza que puede 'saltar' sobre las demás piezas. Los caballos son ideales para amenazar a 2 o más piezas simultáneamente. Son poderosos sobre todo en juegos cerrados, posicionandolos en casillas centrales, ya que en casillas periféricas pierden mucha de su fortaleza. El caballo puede comer o ser comidos por cualquier pieza.",   
    "La ardilla es la más valiosa de las piezas menores. Se trata de un 'super peón'. Cada jugador cuenta con 2 ardillas. Las ardillas mueven como los peones, pero pueden avanzar en todo momento una, dos o hasta tres casillas si están libres frente a ellas, moviéndose solo hacia el frente. Las ardillas, a diferencia de los peones y los conejos, pueden moverse hacia los lados dos casillas, pero no pueden 'comer' hacia los lados, solo moverse. Si una pieza está frente a ellas, no pueden avanzar. No pueden comer de frente, exepto si se trata de un peón o conejo, ya que se lo pueden comer 'al salto'. La ardilla puede comer peones y conejos enemigos 'al salto' y 'al paso', y también otras ardillas enemigas 'al paso' pero no 'al salto'. La ardilla también puede 'saltar' sobre peones y conejos de su propio color (sin ser comidos) pero no pueden saltar sobre otras piezas que no sean peones y conejos. A diferencia de los peones, y como los conejos, pueden avanzar hacia la casilla diagonal que está frente a ellas aunque no estén ocupadas por otra pieza (sin haber comido). Lla ardilla es la única pieza que puede transformarse en dama si corona. La ardilla no puede comer elefantes. La ardilla puede ser comida por cualquier pieza.",
    "El conejo es una pieza menor. Es, por decirlo así, un peón 'potenciado'. Cada jugador cuenta con 3 conejos. Los conejos, a diferencia de los peones, puede mover dos casillas hacia adelante en todo el tablero. No pueden retroceder y, como los peones, comen en diagonal. El conejo no puede avanzar hacia adelante si   la pieza que tienen enfrente no es un peón. Los conejos pueden comer peones 'al salto', eso quiere decir que si un peón está frente al conejo y atrás de él la casilla está libre, el conejo puede saltar sobre el peón y comerlo. Los conejos también pueden 'saltar' sobres peones de su mismo color (sin ser comidos) y también pueden comer peones y conejos 'al paso'. Los conejos pueden, al igual que el peón, comer hacia adelante una casilla en diagonal, pero también pueden ocujpar dicha casilla si está vacía (sin haber 'comido'). Si un conejo 'corona', se transforma en torre. Los conejos no pueden comer elefantes. Los conejos pueden ser comidos por cualquier pieza. Los conejos pueden, al igual que el peón, comer hacia adelante una casilla en diagonal, pero también pueden ocujpar dicha casilla si está vacía. (sin haber 'comido') si un conejo 'corona', se transforma en torre. Los conejos no pueden comer elefantes.",
    "El peón es la pieza menor de menor valor. Cada jugador cuenta con 6 peones. Los peones se mueven igual que en el ajedrez tradicional. El peón mueve avanzando una o dos casillas solo al inicio, pero después solo una. El peón no puede avanzar si tienen una pieza frente a él. Los peones solo comen en diagonal hacia el frente (una casilla) los peones pueden comer 'al paso' a otros peones como en el ajedrez tradicional. La única diferencia con relación al ajedrez tradicional se da con relación a la coronación; si un peón llega hasta la última casilla, se transforma en alfil. Los peones solo se pueden coronar en alfiles. Los peones no pueden comer leónes ni elefantes. Los peones pueden ser comidos 'al salto' y 'al paso' por los conejos y ardillas. Los peones pueden ser comidos por cualquier pieza.",
    ]

    const textos_en = [
    "The King is the most important piece, therefore each team owns only one King. In Leochess90 the monarch has the same mobility as in traditional chess, this means it can only move one spot per movement in any direction. If any player captures the opponent’s King it is checkmate and the game is over.Leochess90 has 4 different types of castling, different from traditional chess where there’s only two kinds: the short and the long one. Castling can only happen between the King and a Rook when the pieces involved have not previously moved from their original setup. Given the symmetry of the chessboard, in Leochess90 the King can do short and long castling to either side, whenever the following conditions are met: • There must be no pieces between the King and the Rook, this means, all the squares between them must be vacant, • The King cannot end up in a square that is under attack by an enemy piece. If the conditions are met, castling is allowed.Castling consists of moving the King two squares towards a Rook, then placing the Rook on the other side of the King, adjacent to it (short casting) or leaving an empty square between the King and the Rook (long castling). The pieces will move 2 or 3 squares to the sides, depending on whether it is a short or a long castling.If a Lion crowns itself it promotes into a King and automatically wins the game, unless the other player can capture the Lion in the immediate next turn after the crowning. The King can capture or be captured by any piece in the game.",        
    "After the King, the Queen is the most valuable piece in Leochess 90. Each player begins the game with two Queens. The Queen is the most powerful defensive and offensive element in the board. A Queen can move diagonally, horizontally, or vertically any number of spots, as in traditional chess, with the only difference that there’s two of them. If a Squirrel crowns itself, it promotes into a Queen. Only Squirrels can be converted into Queens. Queens can capture or be captured by any piece in the game.",
    "The Elephant is a Mayor Piece equivalent in value to the Panther. Each player begins the game with two Elephants. An Elephant can move as a Queen but instead of unlimited spots it can move up to two spots. The Elephant can move freely two spots in any direction except when interfered by a piece of its own team. The Elephant is the only piece that can capture two enemy pieces simultaneously. The only pieces with the power to destroy an Elephant are Mayor and Middle pieces, since it is immune to Minor piece attacks (Pawns, Squirrels, and Rabbits). No piece in the game is allowed to promote into an Elephant when crowning, they are unique. The Elephant is a useful element to break the opponent’s pawn structure and are key offensive and defensive pieces, specially during a close match.",
    "The Panther is a Mayor Piece equivalent in value to the Elephant. Each player begins the game with two Panthers. They are, after the King, the Lion, the Queen and the Rooks, the most valuable pieces in the board. Panthers’ movements are similar to those of Knight’s, but with longer range since they can move three spots along the columns or rows before moving sideways one spot. Panthers can leap over other pieces which allows them to threat multiple pieces simultaneously. No piece in the game is allowed to promote into a Panther when crowning, they are unique. Panthers are crucial at the end of the game and during open games because they can dar dobletes, tripletes, etc. Panthers can capture or be captured by any piece in the game. They aren’t immune, like the Elephants, to Pawns.",
    "The Rook is equivalent in value to the Lion. Each player begins the game with two Rooks. The Rooks move, as in traditional chess, through columns and rows with no range limit, therefore they can occupy all the cells in the board. In the game’s closing, when there are few pieces left on the board, Rooks are crucial due to their wide range of movement. Unlike the lion, the Rook cannot crown and win the game. If a Rabbit crowns itself, it promotes into a Rook. Only Rabbits can be converted into Rooks. Rooks can capture or be captured by any piece in the game.",
    "The Lion is, after the King and the Queens, the most important piece in Leochess90. It’s equivalent in value to the Rooks, even though the latter cannot win the match on their own. The Lion moves as a Rook but is limited to move up to three cells per turn. It can also move as a Bishop, diagonally, up to two cells per turn. If the Lion crowns itself it promotes to King and wins the game, unless the opponent can capture the Lion in its immediate next turn. The Lion is immune to any Pawn attack. If a player loses his/her Lion, it doesn’t mean the match is lost, but that the possibility of winning the game by crowning is over.",
    "The Bishop is the most valuable Middle Piece due to its great reach. Each player begins the game with two Bishops in the same color. Bishops move, as in traditional chess, through diagonals with no range limit. If a Pawn crowns itself, it promotes into a Bishop. The Bishop is a great piece for closings and in open games due to its wide range. In Leochess90, both Bishops of each player are in the same color, moving only through cells of their own color. This gives the player a strategic advantage over its diagonals. The only way to have a Bishop in the opposing color is by crowning. Bishops can capture or be captured by any piece in the game.",
    "The Hound is the least valuable of the Middle Pieces. It can move horizontally, vertically or diagonally but up to two spots per turn. It can also move sideways and backwards two spots but with no power to capture enemy pieces in its way. It cannot move backwards through the diagonal. The Hound can capture any piece in front of it, unlike the Minor Pieces that can only capture diagonally. The Hound is ideal to break the enemy’s Pawn structure. Hounds can capture or be captured by any piece in the game.",
    "The Knight is a Middle Piece more valuable than a Hound but less than a Bishop. Each player begins the game with two Knights. Knights move, as in traditional chess, two squares vertically and one square horizontally, or two squares horizontally and one square vertically thus forming an “L” shape. The Knight and the Panther are the only pieces that can leap over other pieces. Knights are ideal to attack two or more pieces simultaneously and are very powerful during closed games if positions in the center cells. Knights might lose some of their power when positioned on the sidelines. Knights can capture or be captured by any piece in the game.",
    "The Squirrel is the most valuable Minor Piece, sort of a Super Pawn. Each player begins the game with two Squirrels. Squirrels move as Pawns, but can move one, two or three cells forward at any time if free from other pieces. Unlike Rabbits and Pawns, Squirrels can move sideways two spots but cannot capture in that direction. The Squirrel, as the Pawn, captures diagonally, but it can also move one diagonal spot forward without capture. If a piece is in their way forward, a Squirrel cannot occupy its place. Squirrels are not capable of capturing forward with the exception of Pawns and Rabbits, which they can capture by leap. Squirrels can capture enemy Pawns and Rabbits by leap or en passent and can capture enemy Squirrels en pessent but not by leap. A Squirrel can also leap over Pawns and Rabbits of its own color (without capturing them) but cannot leap over other pieces. The Squirrel promotes into a Queen when crowning. A Squirrel cannot capture Elephants. The Squirrel can be captured by any piece in the board.",
    "The Rabbit is a Minor Piece and can be considered as an empowered Pawn. Each player begins the game with three Rabbits. Unlike the Pawns, the Rabbits can move two spots forward at any time. Rabbits cannot move backwards and, as Pawns, capture enemy pieces diagonally. The Rabbit can only move forwards if the piece right in front of it is a Pawn. Rabbits can capture Pawns en passent, this means that if a Pawn is in front of the Rabbit and the immediate cell behind it is empty, the Rabbit may leap over the Pawn and capture it. A Rabbit can also leap over Pawns of its own color (without capturing them) but cannot leap over other pieces. Rabbits can capture enemy Pawns and Rabbits en passent. The Rabbit, as the Pawn, captures diagonally, but it can also move one diagonal spot forward without capture. The Rabbit promotes into a Rook when crowning. A Rabbit cannot capture Elephants. The Rabbit can be captured by any piece in the board.",
    "The Pawn is the least valuable of the Minor Pieces. Each player begins the game with six Pawns that move as in traditional chess. The Pawn can move forward one or two spots on its first turn but only one spot afterwards. Pawns cannot move forward if there is another piece blocking the way. A pawn can capture an enemy piece on either of the two squares diagonally in front of it. It cannot move in diagonal except when capturing. As in traditional chess, Pawns can capture enemy Pawns en passent. In contrast with traditional chess, in Leochess90 the Pawn promotes exclusively into a Bishop when crowning. A Pawn cannot capture Lions nor Elephants. The Pawn can be captured by any piece in the board and can be captured by leap and en passent by Rabbits and Squirrels."
    ]


    const [numeroimagen, setNumeroImagen] = useState(0);
    
    return (
        <section className="fondo_principal">
            <MyNavbar />
            
            <section className="encabezado">
                
                <header className="fondo1" style={{backgroundPosition: "top"}}>
                    <Container>
                        <Row>
                            <Col xs={12} style={{ padding: 0 }}>
                                <div className="site-heading">
                                    <h1>{idioma == "English" ? "LeoChess90 pieces" : "Piezas de LeoChess90"}</h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </header>
                
            </section>
            <section className="movimientos">
            
                <Container>
                    <Row style={{ marginTop: "70px" }}>
                        <Col xs={12} >
                            <h4 style={{ color: "#00a6b4" }}>{idioma == "English" ? "¿How do the pieces move?" : "¿Cómo se mueven las piezas?"}</h4>
                            <h6 className="subtitulo">{idioma == "English" ? "Select the blue buttons to know the strengths of each piece." : "Selecciona los botones azules para saber las fortalezas de cada pieza."}</h6>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "40px",marginBottom: "40px" }}>
                        <Col className="fondo_rayon" xs={12} md={6} style={{ padding: 0}}>
                            <div align="center">
                                <h1 style={{color:"white"}}>{idioma == "English" ? titulos_en[numeroimagen] : titulos[numeroimagen]}</h1>
                                <img src={imagenes[numeroimagen]} className="img-fluid pieza" alt=""></img>
                                <br></br><br></br>

                                <p style={{paddingLeft:"30px",paddingRight:"30px",color:"white",textAlign:"justify"}}>{idioma == "English" ? textos_en[numeroimagen] : textos[numeroimagen]}</p>

                            </div>
                        </Col>
                        <Col xs={12} md={6} >
                            <div id="no-more-tables-mov" style={{marginBottom:"5px"}}>
                            <table className="table" >
                                <thead>
                                    <tr style={{textAlign:"center"}}>
                                        <th>#</th>
                                        <th>{idioma == "English" ? "Piece":"Pieza"}</th>
                                        <th></th>
                                        <th>{idioma == "English" ? "Simbol":"Símbolo"}</th>
                                        <th>{idioma == "English" ? "Value":"Valor"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-title="#">1</td>
                                        <td><button onClick={() => setNumeroImagen(0)}  className="btn btn-info">{idioma == "English" ? "KING":"REY"}</button></td>
                                        <td data-title="Pieza"><img src={icon_rey} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">♔</td>
                                        <td data-title="Valor">10 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">2</td>
                                        <td><button onClick={() => setNumeroImagen(1)} className="btn btn-info">{idioma == "English" ? "QUEEN":"DAMA"}</button></td>
                                        <td data-title="Pieza"><img src={icon_dama} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">♕</td>
                                        <td data-title="Valor">9 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">3</td>
                                        <td><button onClick={() => setNumeroImagen(2)} className="btn btn-info">{idioma == "English" ? "ELEPHANT":"ELEFANTE"}</button></td>
                                        <td data-title="Pieza"><img src={icon_elefante} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">El</td>
                                        <td data-title="Valor">7 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">4</td>
                                        <td><button onClick={() => setNumeroImagen(3)} className="btn btn-info">{idioma == "English" ? "PANTER":"PANTERA"}</button></td>
                                        <td data-title="Pieza"><img src={icon_pantera} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">Pa</td>
                                        <td data-title="Valor">7 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">5</td>
                                        <td><button onClick={() => setNumeroImagen(4)} className="btn btn-info">{idioma == "English" ? "ROOK":"TORRE"}</button></td>
                                        <td data-title="Pieza"><img src={icon_torre} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">♖</td>
                                        <td data-title="Valor">7 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">6</td>
                                        <td><button onClick={() => setNumeroImagen(5)} className="btn btn-info">{idioma == "English" ? "LION":"LEÓN"}</button></td>
                                        <td data-title="Pieza"><img src={icon_leon} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">Le</td>
                                        <td data-title="Valor">8 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">7</td>
                                        <td><button onClick={() => setNumeroImagen(6)} className="btn btn-info">{idioma == "English" ? "BISHOP":"ALFIL"}</button></td>
                                        <td data-title="Pieza"><img src={icon_alfil} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">♗</td>
                                        <td data-title="Valor">6 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">8</td>
                                        <td><button onClick={() => setNumeroImagen(7)} className="btn btn-info">{idioma == "English" ? "HOUND":"PERRO"}</button></td>
                                        <td data-title="Pieza"><img src={icon_perro} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">Pe</td>
                                        <td data-title="Valor">4 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">9</td>
                                        <td><button onClick={() => setNumeroImagen(8)} className="btn btn-info">{idioma == "English" ? "HORSE":"CABALLO"}</button></td>
                                        <td data-title="Pieza"><img src={icon_caballo} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">♘</td>
                                        <td data-title="Valor">5 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">10</td>
                                        <td><button onClick={() => setNumeroImagen(9)} className="btn btn-info">{idioma == "English" ? "SQUIRREL":"ARDILLA"}</button></td>
                                        <td data-title="Pieza"><img src={icon_ardilla} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">Ar</td>
                                        <td data-title="Valor">3 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">11</td>
                                        <td><button onClick={() => setNumeroImagen(10)} className="btn btn-info">{idioma == "English" ? "RABBIT":"CONEJO"}</button></td>
                                        <td data-title="Pieza"><img src={icon_conejo} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">Co</td>
                                        <td data-title="Valor">2 pts</td>
                                    </tr>
                                    <tr>
                                        <td data-title="#">12</td>
                                        <td><button onClick={() => setNumeroImagen(11)} className="btn btn-info">{idioma == "English" ? "PAWN":"PEÓN"}</button></td>
                                        <td data-title="Pieza"><img src={icon_peon} style={{width:"40px"}}></img></td>
                                        <td data-title="Símbolo">♙</td>
                                        <td data-title="Valor">1 pts</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            <br></br>
                            <h4 style={{color:"#00a6b4"}}>{idioma == "English" ? "HOW "+titulos_en[numeroimagen]+" MOVES":"COMO MUEVO"+titulos[numeroimagen]}</h4>
                            <div className="d-flex flex-row">
                                <img src={serpentina3} style={{marginLeft:"15px"}}></img>
                                <h5 style={{color:"#00a6b4",fontWeight:"lighter",marginLeft:"5px"}}>{idioma == "English" ? "learn to play.":"aprende a jugar."}</h5>
                            </div>
                            <Row>
                                <Col xs={12} md={6} >
                                    <img src={tableros[numeroimagen]} className="tableros img-fluid" alt=""></img>        
                                </Col>
                                <Col xs={12} md={6} className="referencias">
                                    <img src={movimiento} ></img>
                                    <p style={{color:"white",marginTop:"10px"}}>{idioma == "English" ? "MOVE OR TAKE A PIECE":"MOVIMIENTO O COMER"}</p>
                                    <br></br> 
                                    <img src={comer} ></img>
                                    <p style={{color:"white",marginTop:"10px"}}>{idioma == "English" ? "JUST MOVE":"SOLO MOVIMIENTO"}</p>
                                </Col>
                            </Row>
                        </Col>
                        
                        
                        
                    </Row>
                </Container>
            </section>
            <MyFooter/>
        </section>
    );
}
